.order-id{
    padding-bottom: 30px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
.id-view{
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 5px;
    box-shadow: 0 5px 10px rgb(0 0 0 / 0.2);
}
.id-view .title{
    white-space: nowrap;
    padding-left: 10px;
    
}
.id-view .id{
    width: 100%;
    padding: 8px 5px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    padding-left: 10px;
}
.id-view button {
    padding: 10px 15px;
    color: azure;
    background-color: rgb(0, 81, 255);
    border-radius: 0px 5px 5px 0px;
    outline: none;
    border: 0;
    font-weight: bold;
    cursor: pointer;
}

/* .close {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.close i {
    cursor: pointer;
    margin-top: -5px;
    margin-right: -5px;
} */
.amount {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 20px 0px;
    padding: 0 15px;
}
.amount .box{
    width: 70px;
    height: 60px;
    font-weight: bolder;
    font-size: 20px;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.amount .content {
    width: 100%;
    text-align: right;
}
.amount .content h3{
    float: left;
    margin-left: 10px;
    font-size: small;
    width: 50%;
    text-align: left;
}
.fields {
    padding: 30px;
}

button:disabled,
button[disabled]{
  background-color: #999999;
  color: #666666;
  cursor: default;
}